* {
  -webkit-tap-highlight-color: transparent;
  outline: none !important;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently
                                supported by Chrome, Edge, Opera and Firefox */
}

/* #region Fonts */


@font-face {
  font-family: CustomFont0;
  src: url("./libraries/Fonts/Ubuntu-L.ttf");
}

@font-face {
  font-family: CustomFont1;
  src: url("./libraries/Fonts/Ubuntu-R.ttf");
}

@font-face {
  font-family: CustomFont2;
  src: url("./libraries/Fonts/Ubuntu-B.ttf");
}

@font-face {
  font-family: CustomFont3;
  src: url("./libraries/Fonts/Ubuntu-C.ttf");
}

@font-face {
  font-family: CustomFont4;
  src: url("./libraries/Fonts/Ubuntu-M.ttf");
}

@font-face {
  font-family: CustomFont5;
  src: url("./libraries/Fonts/UbuntuMono-R.ttf");
}

/* #endregion */

a {
text-decoration: none;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.page{
  height: calc(100% - 50px);
  width: 100%;
  /* overflow: hidden;
  overflow-y: auto; */
  text-align: center;
  /* position: absolute; */
}

.page>div{
  margin: auto;
  /* padding: 9px; */
  text-align: left;
  height: 100%;
  padding-bottom: 20px;
    /* overflow: auto; */
}
