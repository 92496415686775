.Avatar{
    font-size:  50px !important;
    width:80px !important;
    height:80px !important;
    background-color: rgb(224, 228, 227) !important;
}

.avatarDiv{
    margin-top: -40px !important;
    font-weight: 600; 
}

.Greet{
   font-size: 30px; 
   padding-top: 4%;
   /* padding-left: 45%; */
   
   text-align: center !important;
}

.ProfileList{
    /* margin-left: 40%; */
    margin-top: 3%;
}
.ProfileMenu{
    /* margin-left:5%; */
}


.profileContainer button {
    height: 100%;
    width: 100%;
    text-align: left;
}

.profileContainer hr{
    margin: 0 !important;
}

.profileContainer ul{
    padding: 0 !important;
    margin-top: 10px;
}

.profileContainer button{
    height: 53px !important;
}

.profileContainer{
justify-content: center;
width: 100%;
}

.profileContainer > div {
    margin: auto;
}