@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?35378127');
  src: url('../font/fontello.eot?35378127#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?35378127') format('woff2'),
       url('../font/fontello.woff?35378127') format('woff'),
       url('../font/fontello.ttf?35378127') format('truetype'),
       url('../font/fontello.svg?35378127#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?35378127#fontello') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: never;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-home:before { content: '\e800'; } /* '' */
.icon-home-1:before { content: '\e801'; } /* '' */
.icon-ok-circled:before { content: '\e802'; } /* '' */
.icon-logout:before { content: '\e803'; } /* '' */
.icon-login:before { content: '\e804'; } /* '' */
.icon-phone:before { content: '\e805'; } /* '' */
.icon-cog-alt:before { content: '\e806'; } /* '' */
.icon-cog:before { content: '\e807'; } /* '' */
.icon-trash-empty:before { content: '\e808'; } /* '' */
.icon-fire:before { content: '\e809'; } /* '' */
.icon-tasks:before { content: '\e80a'; } /* '' */
.icon-briefcase:before { content: '\e80b'; } /* '' */
.icon-inbox:before { content: '\e80c'; } /* '' */
.icon-pencil:before { content: '\e80d'; } /* '' */
.icon-pencil-circled:before { content: '\e80e'; } /* '' */
.icon-edit-alt:before { content: '\e80f'; } /* '' */
.icon-wrench:before { content: '\e810'; } /* '' */
.icon-wrench-circled:before { content: '\e811'; } /* '' */
.icon-clock-circled:before { content: '\e812'; } /* '' */
.icon-heart:before { content: '\e813'; } /* '' */
.icon-search:before { content: '\e814'; } /* '' */
.icon-phone-1:before { content: '\e815'; } /* '' */
.icon-adult:before { content: '\e816'; } /* '' */
.icon-home-2:before { content: '\e817'; } /* '' */
.icon-home-circled:before { content: '\e818'; } /* '' */
.icon-search-circled:before { content: '\e819'; } /* '' */
.icon-video:before { content: '\e81a'; } /* '' */
.icon-videocam:before { content: '\e81b'; } /* '' */
.icon-video-circled:before { content: '\e81c'; } /* '' */
.icon-video-chat:before { content: '\e81d'; } /* '' */
.icon-at:before { content: '\e81e'; } /* '' */
.icon-mail:before { content: '\e81f'; } /* '' */
.icon-flash:before { content: '\e820'; } /* '' */
.icon-money:before { content: '\e821'; } /* '' */
.icon-chart-pie:before { content: '\e822'; } /* '' */
.icon-dollar:before { content: '\e823'; } /* '' */
.icon-cd:before { content: '\e824'; } /* '' */
.icon-calendar-inv:before { content: '\e825'; } /* '' */
.icon-mail-1:before { content: '\e826'; } /* '' */
.icon-search-1:before { content: '\e827'; } /* '' */
.icon-tag:before { content: '\e828'; } /* '' */
.icon-tag-empty:before { content: '\e829'; } /* '' */
.icon-cog-1:before { content: '\e82a'; } /* '' */
.icon-chart-pie-alt:before { content: '\e82b'; } /* '' */
.icon-chart-pie-1:before { content: '\e82c'; } /* '' */
.icon-pinterest:before { content: '\e82d'; } /* '' */
.icon-twitter-1:before { content: '\e82e'; } /* '' */
.icon-skype-1:before { content: '\e82f'; } /* '' */
.icon-spin1:before { content: '\e830'; } /* '' */
.icon-spin2:before { content: '\e831'; } /* '' */
.icon-linkedin:before { content: '\e832'; } /* '' */
.icon-facebook-1:before { content: '\e833'; } /* '' */
.icon-spin4:before { content: '\e834'; } /* '' */
.icon-chart-pie-2:before { content: '\e835'; } /* '' */
.icon-location-1:before { content: '\e836'; } /* '' */
.icon-export:before { content: '\e837'; } /* '' */
.icon-edit:before { content: '\e838'; } /* '' */
.icon-spin6:before { content: '\e839'; } /* '' */
.icon-direction:before { content: '\e83a'; } /* '' */
.icon-map:before { content: '\e83b'; } /* '' */
.icon-calendar-1:before { content: '\e83c'; } /* '' */
.icon-wrench-1:before { content: '\e83d'; } /* '' */
.icon-menu:before { content: '\e83e'; } /* '' */
.icon-check:before { content: '\e83f'; } /* '' */
.icon-check-outline:before { content: '\e840'; } /* '' */
.icon-warning:before { content: '\e841'; } /* '' */
.icon-location-outline:before { content: '\e842'; } /* '' */
.icon-trash-1:before { content: '\e843'; } /* '' */
.icon-gift:before { content: '\e844'; } /* '' */
.icon-home-3:before { content: '\e845'; } /* '' */
.icon-home-outline:before { content: '\e846'; } /* '' */
.icon-cancel-alt-filled:before { content: '\e847'; } /* '' */
.icon-cancel-alt:before { content: '\e848'; } /* '' */
.icon-user-1:before { content: '\e849'; } /* '' */
.icon-user-add:before { content: '\e84a'; } /* '' */
.icon-user-delete:before { content: '\e84b'; } /* '' */
.icon-search-2:before { content: '\e84c'; } /* '' */
.icon-cd-1:before { content: '\e84d'; } /* '' */
.icon-minus-squared-1:before { content: '\e84e'; } /* '' */
.icon-plus-squared-1:before { content: '\e84f'; } /* '' */
.icon-check-1:before { content: '\e850'; } /* '' */
.icon-cancel:before { content: '\e851'; } /* '' */
.icon-menu-1:before { content: '\e852'; } /* '' */
.icon-picture:before { content: '\e853'; } /* '' */
.icon-mail-2:before { content: '\e854'; } /* '' */
.icon-credit-card:before { content: '\e855'; } /* '' */
.icon-fire-1:before { content: '\e856'; } /* '' */
.icon-check-2:before { content: '\e857'; } /* '' */
.icon-briefcase-1:before { content: '\e858'; } /* '' */
.icon-qrcode:before { content: '\e859'; } /* '' */
.icon-calendar-2:before { content: '\e85a'; } /* '' */
.icon-emo-thumbsup:before { content: '\e85b'; } /* '' */
.icon-emo-coffee:before { content: '\e85c'; } /* '' */
.icon-emo-sunglasses:before { content: '\e85d'; } /* '' */
.icon-emo-cry:before { content: '\e85e'; } /* '' */
.icon-emo-surprised:before { content: '\e85f'; } /* '' */
.icon-crown:before { content: '\e860'; } /* '' */
.icon-emo-displeased:before { content: '\e861'; } /* '' */
.icon-calendar-3:before { content: '\e862'; } /* '' */
.icon-logout-1:before { content: '\e863'; } /* '' */
.icon-cloud-thunder:before { content: '\e864'; } /* '' */
.icon-archive:before { content: '\e865'; } /* '' */
.icon-box:before { content: '\e866'; } /* '' */
.icon-trash-2:before { content: '\e867'; } /* '' */
.icon-trophy:before { content: '\e868'; } /* '' */
.icon-certificate:before { content: '\e869'; } /* '' */
.icon-certificate-outline:before { content: '\e86a'; } /* '' */
.icon-ok-circle:before { content: '\e86b'; } /* '' */
.icon-cancel-circle:before { content: '\e86c'; } /* '' */
.icon-chat-inv:before { content: '\e86d'; } /* '' */
.icon-post:before { content: '\e86e'; } /* '' */
.icon-trash-3:before { content: '\e86f'; } /* '' */
.icon-college:before { content: '\e870'; } /* '' */
.icon-cinema:before { content: '\e871'; } /* '' */
.icon-garden:before { content: '\e872'; } /* '' */
.icon-angellist:before { content: '\e873'; } /* '' */
.icon-minus-circled:before { content: '\e874'; } /* '' */
.icon-plus-circled:before { content: '\e875'; } /* '' */
.icon-cancel-circled:before { content: '\e876'; } /* '' */
.icon-ok-circled-1:before { content: '\e877'; } /* '' */
.icon-star-empty:before { content: '\e878'; } /* '' */
.icon-star:before { content: '\e879'; } /* '' */
.icon-comment-alt:before { content: '\e87a'; } /* '' */
.icon-bell:before { content: '\e87b'; } /* '' */
.icon-thumbs-down:before { content: '\e87c'; } /* '' */
.icon-thumbs-up:before { content: '\e87d'; } /* '' */
.icon-info-circled:before { content: '\e87e'; } /* '' */
.icon-location-2:before { content: '\e87f'; } /* '' */
.icon-exclamation:before { content: '\e880'; } /* '' */
.icon-calendar-4:before { content: '\e881'; } /* '' */
.icon-left-circled:before { content: '\e882'; } /* '' */
.icon-right-circled:before { content: '\e883'; } /* '' */
.icon-qrcode-1:before { content: '\e884'; } /* '' */
.icon-check-3:before { content: '\e885'; } /* '' */
.icon-check-empty-1:before { content: '\e886'; } /* '' */
.icon-female:before { content: '\e887'; } /* '' */
.icon-male:before { content: '\e888'; } /* '' */
.icon-ok-circle-1:before { content: '\e889'; } /* '' */
.icon-link:before { content: '\e88a'; } /* '' */
.icon-arrows-cw:before { content: '\e88b'; } /* '' */
.icon-logout-2:before { content: '\e88c'; } /* '' */
.icon-attention:before { content: '\e88d'; } /* '' */
.icon-home-4:before { content: '\e88e'; } /* '' */
.icon-arrows-cw-1:before { content: '\e88f'; } /* '' */
.icon-cancel-squared:before { content: '\e890'; } /* '' */
.icon-cancel-circled-1:before { content: '\e891'; } /* '' */
.icon-cancel-circled-outline:before { content: '\e892'; } /* '' */
.icon-leaf:before { content: '\e893'; } /* '' */
.icon-cloud:before { content: '\e894'; } /* '' */
.icon-eye:before { content: '\e895'; } /* '' */
.icon-chat:before { content: '\e896'; } /* '' */
.icon-cloud-1:before { content: '\e897'; } /* '' */
.icon-key:before { content: '\e898'; } /* '' */
.icon-key-1:before { content: '\e899'; } /* '' */
.icon-key-2:before { content: '\e89a'; } /* '' */
.icon-feather:before { content: '\e89b'; } /* '' */
.icon-export-1:before { content: '\e89c'; } /* '' */
.icon-upload:before { content: '\e89d'; } /* '' */
.icon-download:before { content: '\e89e'; } /* '' */
.icon-progress-1:before { content: '\e89f'; } /* '' */
.icon-progress-2:before { content: '\e8a0'; } /* '' */
.icon-progress-3:before { content: '\e8a1'; } /* '' */
.icon-list-add:before { content: '\e8a2'; } /* '' */
.icon-chart-line:before { content: '\e8a3'; } /* '' */
.icon-chart-pie-3:before { content: '\e8a4'; } /* '' */
.icon-erase:before { content: '\e8a5'; } /* '' */
.icon-infinity:before { content: '\e8a6'; } /* '' */
.icon-briefcase-2:before { content: '\e8a7'; } /* '' */
.icon-lifebuoy:before { content: '\e8a8'; } /* '' */
.icon-language:before { content: '\e8a9'; } /* '' */
.icon-database:before { content: '\e8aa'; } /* '' */
.icon-gauge-1:before { content: '\e8ab'; } /* '' */
.icon-rocket:before { content: '\e8ac'; } /* '' */
.icon-th-large-outline:before { content: '\e8ad'; } /* '' */
.icon-th-large:before { content: '\e8ae'; } /* '' */
.icon-th:before { content: '\e8af'; } /* '' */
.icon-th-outline:before { content: '\e8b0'; } /* '' */
.icon-th-list:before { content: '\e8b1'; } /* '' */
.icon-th-list-outline:before { content: '\e8b2'; } /* '' */
.icon-videocam-1:before { content: '\e8b3'; } /* '' */
.icon-star-filled:before { content: '\e8b4'; } /* '' */
.icon-star-1:before { content: '\e8b5'; } /* '' */
.icon-pin-outline:before { content: '\e8b6'; } /* '' */
.icon-pin:before { content: '\e8b7'; } /* '' */
.icon-eye-1:before { content: '\e8b8'; } /* '' */
.icon-address:before { content: '\e8b9'; } /* '' */
.icon-bat1:before { content: '\e8ba'; } /* '' */
.icon-bat2:before { content: '\e8bb'; } /* '' */
.icon-bat3:before { content: '\e8bc'; } /* '' */
.icon-bat4:before { content: '\e8bd'; } /* '' */
.icon-bat-charge:before { content: '\e8be'; } /* '' */
.icon-cloud-flash:before { content: '\e8bf'; } /* '' */
.icon-rain:before { content: '\e8c0'; } /* '' */
.icon-cloud-sun:before { content: '\e8c1'; } /* '' */
.icon-drizzle:before { content: '\e8c2'; } /* '' */
.icon-sun-filled:before { content: '\e8c3'; } /* '' */
.icon-sun:before { content: '\e8c4'; } /* '' */
.icon-cloud-2:before { content: '\e8c5'; } /* '' */
.icon-flash-1:before { content: '\e8c6'; } /* '' */
.icon-flash-outline:before { content: '\e8c7'; } /* '' */
.icon-moon:before { content: '\e8c8'; } /* '' */
.icon-chart-bar:before { content: '\e8c9'; } /* '' */
.icon-chart-bar-outline:before { content: '\e8ca'; } /* '' */
.icon-chart-pie-outline:before { content: '\e8cb'; } /* '' */
.icon-tree:before { content: '\e8cc'; } /* '' */
.icon-flow-cross:before { content: '\e8cd'; } /* '' */
.icon-flow-merge:before { content: '\e8ce'; } /* '' */
.icon-flow-split:before { content: '\e8cf'; } /* '' */
.icon-looped-square-interest:before { content: '\e8d0'; } /* '' */
.icon-looped-square-outline:before { content: '\e8d1'; } /* '' */
.icon-sort-alphabet-outline:before { content: '\e8d2'; } /* '' */
.icon-sort-alphabet:before { content: '\e8d3'; } /* '' */
.icon-picture-1:before { content: '\e8d4'; } /* '' */
.icon-minus-circle:before { content: '\e8d5'; } /* '' */
.icon-plus-circle:before { content: '\e8d6'; } /* '' */
.icon-quote-right-alt:before { content: '\e8d7'; } /* '' */
.icon-quote-left-alt:before { content: '\e8d8'; } /* '' */
.icon-lock-empty:before { content: '\e8d9'; } /* '' */
.icon-lock:before { content: '\e8da'; } /* '' */
.icon-comment-inv:before { content: '\e8db'; } /* '' */
.icon-volume-up:before { content: '\e8dc'; } /* '' */
.icon-volume-off:before { content: '\e8dd'; } /* '' */
.icon-mic:before { content: '\e8de'; } /* '' */
.icon-headphones:before { content: '\e8df'; } /* '' */
.icon-share:before { content: '\e8e0'; } /* '' */
.icon-resize-full:before { content: '\e8e1'; } /* '' */
.icon-resize-small:before { content: '\e8e2'; } /* '' */
.icon-resize-horizontal:before { content: '\e8e3'; } /* '' */
.icon-resize-vertical:before { content: '\e8e4'; } /* '' */
.icon-popup:before { content: '\e8e5'; } /* '' */
.icon-equalizer:before { content: '\e8e6'; } /* '' */
.icon-mobile:before { content: '\e8e7'; } /* '' */
.icon-key-inv:before { content: '\e8e8'; } /* '' */
.icon-key-3:before { content: '\e8e9'; } /* '' */
.icon-cloud-moon-inv:before { content: '\e8ea'; } /* '' */
.icon-cloud-sun-inv:before { content: '\e8eb'; } /* '' */
.icon-clouds-flash-alt:before { content: '\e8ec'; } /* '' */
.icon-help-circled:before { content: '\e8ed'; } /* '' */
.icon-hospital:before { content: '\e8ee'; } /* '' */
.icon-fire-station:before { content: '\e8ef'; } /* '' */
.icon-guest:before { content: '\e8f0'; } /* '' */
.icon-appstore:before { content: '\e8f1'; } /* '' */
.icon-android:before { content: '\e8f2'; } /* '' */
.icon-html5:before { content: '\e8f3'; } /* '' */
.icon-macstore:before { content: '\e8f4'; } /* '' */
.icon-github-circled:before { content: '\e8f5'; } /* '' */
.icon-pocket:before { content: '\e8f6'; } /* '' */
.icon-instagram:before { content: '\e8f7'; } /* '' */
.icon-stackoverflow:before { content: '\e8f8'; } /* '' */
.icon-dropbox:before { content: '\e8f9'; } /* '' */
.icon-th-1:before { content: '\e8fa'; } /* '' */
.icon-th-large-1:before { content: '\e8fb'; } /* '' */
.icon-tag-1:before { content: '\e8fc'; } /* '' */
.icon-tags:before { content: '\e8fd'; } /* '' */
.icon-cog-circled:before { content: '\e8fe'; } /* '' */
.icon-filter:before { content: '\e8ff'; } /* '' */
.icon-th-large-2:before { content: '\e900'; } /* '' */
.icon-comment:before { content: '\e901'; } /* '' */
.icon-down-micro:before { content: '\e902'; } /* '' */
.icon-up-micro:before { content: '\e903'; } /* '' */
.icon-resize-full-1:before { content: '\e904'; } /* '' */
.icon-plus:before { content: '\e905'; } /* '' */
.icon-plus-1:before { content: '\e906'; } /* '' */
.icon-plus-outline:before { content: '\e907'; } /* '' */
.icon-plus-2:before { content: '\e908'; } /* '' */
.icon-updown-circle:before { content: '\e909'; } /* '' */
.icon-up-circle:before { content: '\e90a'; } /* '' */
.icon-right-circle:before { content: '\e90b'; } /* '' */
.icon-left-circle:before { content: '\e90c'; } /* '' */
.icon-down-circle:before { content: '\e90d'; } /* '' */
.icon-right-open:before { content: '\e90e'; } /* '' */
.icon-left-open:before { content: '\e90f'; } /* '' */
.icon-split:before { content: '\e910'; } /* '' */
.icon-right-circle-1:before { content: '\e911'; } /* '' */
.icon-up-circle-1:before { content: '\e912'; } /* '' */
.icon-left-circle-1:before { content: '\e913'; } /* '' */
.icon-down-circle-1:before { content: '\e914'; } /* '' */
.icon-down:before { content: '\e915'; } /* '' */
.icon-left:before { content: '\e916'; } /* '' */
.icon-right:before { content: '\e917'; } /* '' */
.icon-up:before { content: '\e918'; } /* '' */
.icon-left-open-1:before { content: '\e919'; } /* '' */
.icon-right-open-1:before { content: '\e91a'; } /* '' */
.icon-right-open-outline:before { content: '\e91b'; } /* '' */
.icon-users:before { content: '\e91c'; } /* '' */
.icon-users-1:before { content: '\e91d'; } /* '' */
.icon-group:before { content: '\e91e'; } /* '' */
.icon-users-2:before { content: '\e91f'; } /* '' */
.icon-users-outline:before { content: '\e920'; } /* '' */
.icon-emo-laugh:before { content: '\e921'; } /* '' */
.icon-emo-squint:before { content: '\e922'; } /* '' */
.icon-emo-shoot:before { content: '\e923'; } /* '' */
.icon-emo-angry:before { content: '\e924'; } /* '' */
.icon-emo-grin:before { content: '\e925'; } /* '' */
.icon-emo-beer:before { content: '\e926'; } /* '' */
.icon-emo-tongue:before { content: '\e927'; } /* '' */
.icon-emo-devil:before { content: '\e928'; } /* '' */
.icon-emo-sleep:before { content: '\e929'; } /* '' */
.icon-emo-unhappy:before { content: '\e92a'; } /* '' */
.icon-emo-wink2:before { content: '\e92b'; } /* '' */
.icon-emo-wink:before { content: '\e92c'; } /* '' */
.icon-emo-happy:before { content: '\e92d'; } /* '' */
.icon-emo-saint:before { content: '\e92e'; } /* '' */
.icon-cancel-circle-1:before { content: '\e92f'; } /* '' */
.icon-spin3:before { content: '\e930'; } /* '' */
.icon-tag-2:before { content: '\e931'; } /* '' */
.icon-tags-1:before { content: '\e932'; } /* '' */
.icon-bookmark:before { content: '\e933'; } /* '' */
.icon-minus-circled-1:before { content: '\e934'; } /* '' */
.icon-flag:before { content: '\e935'; } /* '' */
.icon-dot:before { content: '\e936'; } /* '' */
.icon-dot-2:before { content: '\e937'; } /* '' */
.icon-dot-3:before { content: '\e938'; } /* '' */
.icon-ok:before { content: '\e939'; } /* '' */
.icon-cancel-1:before { content: '\e93a'; } /* '' */
.icon-user-3:before { content: '\e93b'; } /* '' */
.icon-location-alt:before { content: '\e93c'; } /* '' */
.icon-attach:before { content: '\e93d'; } /* '' */
.icon-attach-circled:before { content: '\e93e'; } /* '' */
.icon-cancel-2:before { content: '\e93f'; } /* '' */
.icon-ok-1:before { content: '\e940'; } /* '' */
.icon-clock:before { content: '\e941'; } /* '' */
.icon-th-list-1:before { content: '\e942'; } /* '' */
.icon-th-2:before { content: '\e943'; } /* '' */
.icon-attach-1:before { content: '\e944'; } /* '' */
.icon-spin5:before { content: '\e945'; } /* '' */
.icon-basket:before { content: '\e946'; } /* '' */
.icon-basket-1:before { content: '\e947'; } /* '' */
.icon-basket-2:before { content: '\e948'; } /* '' */
.icon-eye-3:before { content: '\e949'; } /* '' */
.icon-eye-off:before { content: '\e94a'; } /* '' */
.icon-braille:before { content: '\e94b'; } /* '' */
.icon-bookmark-1:before { content: '\e94c'; } /* '' */
.icon-bookmark-empty:before { content: '\e94d'; } /* '' */
.icon-flickr:before { content: '\e94e'; } /* '' */
.icon-pause:before { content: '\f00e'; } /* '' */
.icon-play:before { content: '\f00f'; } /* '' */
.icon-upload-cloud-1:before { content: '\f014'; } /* '' */
.icon-download-cloud-1:before { content: '\f015'; } /* '' */
.icon-data-science:before { content: '\f016'; } /* '' */
.icon-location:before { content: '\f031'; } /* '' */
.icon-facebook:before { content: '\f052'; } /* '' */
.icon-twitter:before { content: '\f058'; } /* '' */
.icon-user:before { content: '\f061'; } /* '' */
.icon-user-female:before { content: '\f063'; } /* '' */
.icon-users-3:before { content: '\f064'; } /* '' */
.icon-ok-circled-2:before { content: '\f06d'; } /* '' */
.icon-cancel-circled-2:before { content: '\f06e'; } /* '' */
.icon-stop:before { content: '\f080'; } /* '' */
.icon-eye-2:before { content: '\f082'; } /* '' */
.icon-info-circled-1:before { content: '\f085'; } /* '' */
.icon-info-circled-alt:before { content: '\f086'; } /* '' */
.icon-link-ext:before { content: '\f08e'; } /* '' */
.icon-check-empty:before { content: '\f096'; } /* '' */
.icon-left-circled-1:before { content: '\f0a8'; } /* '' */
.icon-right-circled-1:before { content: '\f0a9'; } /* '' */
.icon-up-circled:before { content: '\f0aa'; } /* '' */
.icon-down-circled:before { content: '\f0ab'; } /* '' */
.icon-tasks-1:before { content: '\f0ae'; } /* '' */
.icon-mail-alt:before { content: '\f0e0'; } /* '' */
.icon-gauge:before { content: '\f0e4'; } /* '' */
.icon-download-cloud:before { content: '\f0ed'; } /* '' */
.icon-upload-cloud:before { content: '\f0ee'; } /* '' */
.icon-suitcase:before { content: '\f0f2'; } /* '' */
.icon-plus-squared:before { content: '\f0fe'; } /* '' */
.icon-circle-empty:before { content: '\f10c'; } /* '' */
.icon-quote-left:before { content: '\f10d'; } /* '' */
.icon-quote-right:before { content: '\f10e'; } /* '' */
.icon-spinner:before { content: '\f110'; } /* '' */
.icon-circle:before { content: '\f111'; } /* '' */
.icon-smile:before { content: '\f118'; } /* '' */
.icon-frown:before { content: '\f119'; } /* '' */
.icon-meh:before { content: '\f11a'; } /* '' */
.icon-calendar-empty:before { content: '\f133'; } /* '' */
.icon-minus-squared:before { content: '\f146'; } /* '' */
.icon-sort-name-up:before { content: '\f15d'; } /* '' */
.icon-sort-name-down:before { content: '\f15e'; } /* '' */
.icon-sort-alt-up:before { content: '\f160'; } /* '' */
.icon-sort-alt-down:before { content: '\f161'; } /* '' */
.icon-sort-number-up:before { content: '\f162'; } /* '' */
.icon-sort-number-down:before { content: '\f163'; } /* '' */
.icon-youtube:before { content: '\f167'; } /* '' */
.icon-youtube-play:before { content: '\f16a'; } /* '' */
.icon-pagelines:before { content: '\f18c'; } /* '' */
.icon-stackexchange:before { content: '\f18d'; } /* '' */
.icon-paper-plane:before { content: '\f1d8'; } /* '' */
.icon-paper-plane-empty:before { content: '\f1d9'; } /* '' */
.icon-history:before { content: '\f1da'; } /* '' */
.icon-sliders:before { content: '\f1de'; } /* '' */
.icon-binoculars:before { content: '\f1e5'; } /* '' */
.icon-gwallet:before { content: '\f1ee'; } /* '' */
.icon-cc-visa:before { content: '\f1f0'; } /* '' */
.icon-cc-mastercard:before { content: '\f1f1'; } /* '' */
.icon-cc-paypal:before { content: '\f1f4'; } /* '' */
.icon-trash:before { content: '\f1f8'; } /* '' */
.icon-copyright:before { content: '\f1f9'; } /* '' */
.icon-toggle-off:before { content: '\f204'; } /* '' */
.icon-toggle-on:before { content: '\f205'; } /* '' */
.icon-cart-arrow-down:before { content: '\f218'; } /* '' */
.icon-user-secret:before { content: '\f21b'; } /* '' */
.icon-venus:before { content: '\f221'; } /* '' */
.icon-mars:before { content: '\f222'; } /* '' */
.icon-user-times:before { content: '\f235'; } /* '' */
.icon-opencart:before { content: '\f23d'; } /* '' */
.icon-get-pocket:before { content: '\f265'; } /* '' */
.icon-calendar-times-o:before { content: '\f273'; } /* '' */
.icon-calendar-check-o:before { content: '\f274'; } /* '' */
.icon-edge:before { content: '\f282'; } /* '' */
.icon-credit-card-alt:before { content: '\f283'; } /* '' */
.icon-modx:before { content: '\f285'; } /* '' */
.icon-shopping-basket:before { content: '\f291'; } /* '' */
.icon-bluetooth:before { content: '\f293'; } /* '' */
.icon-bluetooth-b:before { content: '\f294'; } /* '' */
.icon-envelope-open:before { content: '\f2b6'; } /* '' */
.icon-envelope-open-o:before { content: '\f2b7'; } /* '' */
.icon-user-circle:before { content: '\f2bd'; } /* '' */
.icon-user-circle-o:before { content: '\f2be'; } /* '' */
.icon-id-card:before { content: '\f2c2'; } /* '' */
.icon-id-card-o:before { content: '\f2c3'; } /* '' */
.icon-window-close:before { content: '\f2d3'; } /* '' */
.icon-eercast:before { content: '\f2da'; } /* '' */
.icon-superpowers:before { content: '\f2dd'; } /* '' */
.icon-facebook-rect:before { content: '\f301'; } /* '' */
.icon-skype:before { content: '\f30b'; } /* '' */
.icon-tumblr-rect:before { content: '\f310'; } /* '' */
.icon-linkedin-rect:before { content: '\f312'; } /* '' */
.icon-win8:before { content: '\f325'; } /* '' */
.icon-visa:before { content: '\f328'; } /* '' */
.icon-mastercard:before { content: '\f329'; } /* '' */
.icon-qq:before { content: '\f32a'; } /* '' */
.icon-codepen:before { content: '\f32c'; } /* '' */
.icon-picasa:before { content: '\f345'; } /* '' */
.icon-calendar:before { content: '\f4c5'; } /* '' */
.icon-lock-alt:before { content: '\f510'; } /* '' */
.icon-lock-1:before { content: '\f512'; } /* '' */
.icon-spread:before { content: '\f527'; } /* '' */
.icon-spinner1:before { content: '\f528'; } /* '' */