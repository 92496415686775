  .loader{
    text-align: center;
    margin-top: 90px;
    text-align: center;
    justify-content: center;
  }
  .loader > * {
    color: #009999 ;
    max-width: 200px;
    margin: auto;
  }
  