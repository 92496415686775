.itemContainer{
    max-height: 77px;
}
.img{
    /* max-height: 90px; */
    max-width: 64px;
}

.logo{
    max-height: 25px;
}

.shopLogo{
    float: right;
    max-width: 40px;
    vertical-align: top;
    position: absolute;
    right: 6px;
}
.item{
    height: 77px;
    position: relative;
    cursor: pointer;
    width: 100%;
}

.leftSide {
    display: inline-block;
    height: 100%;
    width: 23%;
}
.imageText {
    position: absolute;
    background: #4a4a4a;
    color: #f5f5f5;
    height: 17px;
    z-index: 2;
    bottom: 2px;
    text-align: center;
    left: 40px;
    border-radius: 6px;
    font-size: 12px;
    padding: 1px 8px 0 8px;
    display: none;
}

.rightSide {
    vertical-align: top;
    display: inline-block;
    height: 100%;
    width: 76%;
    padding-top: 13px;
}

.text {
    font-size: 20px;
    font-weight: bold;
    max-width: 80%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}


.swipeBox{
    color: white;
    font-size: 12px;
    height: 100%;
    width: 100%;
}
.listIcon{
    font-size: 25px;
}

.swipeBox > .content{
    width: 200px;
    text-align: center;
    padding-top:20px;
    font-size: 10px;
}

.swipeToLeft{
    background-color: #3a8c3a;
    text-align: right;
}
.swipeToLeft > .content{
    margin: auto -42px auto auto;
}
.swipeToRight{
    background-color: red;
    text-align: left;
}
.swipeToRight > .content{   
    margin-left: -46px;
}

