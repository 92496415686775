.searchContainer { 

  position: fixed;
  width: 100%;
  top: 0;
}
.root {
    padding: 2px 4px;
    display: flex;
    align-items: center;
    width: 99%;
    
    margin: auto;
    margin-bottom: 15px;
    margin-top: 10px;
    /* position: absolute; */
  }
  
  .input {
    /* margin-left: theme.spacing(1); */
    flex: 1;
  }

  .iconButton {
    padding: 10px;
  }

  .divider {
    height: 284px;
    margin: 4px;
  }

  .loader{
    text-align: center;
    margin-top: 100px;
  }

  .loaderColor {
    color: #009999 !important;
  }
  