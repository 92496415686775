
  /* Hooks button */
  .button {
    cursor: pointer;
    display: block;
    font-size: 1.1em;
    box-sizing: content-box;
    margin: 20px auto 0px;
    width: 270px;
    padding: 13px 5px;
    border-radius: 24px;
    border-color: transparent;
    background-color: white;
    box-shadow: 0px 16px 60px rgba(78, 79, 114, 0.08);
    position: relative;
    transition: ease 1.5s;
  }

  .button:hover{
    box-shadow: 4px -9px 40px 0px #b9b9b9, 0px 9px 40px 0px #b9b9b9
  }
  .buttonText {
    color: #009999;
    font-weight: bolder;
    margin-left:30px;
  }
  
  .icon {
    height: 25px;
    width: 25px;
    margin-right: 0px;
    position: absolute;
    left: 21px;
    align-items: center;
    top: 11.3px;
  }
  .loginLoadder{
      width:200px;
      margin: auto;
  }