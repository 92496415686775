.tabNavContainer{
    background-color: #f8f8f8;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;
    box-shadow:0 15px 10px rgba(0,0, 0, 0.16),
    0 3px 6px rgba(0,0, 0, 0.16);
    display: flex;
    padding: 0;
    justify-content: center;
    position: fixed;
    bottom: 0;
    width: 100%;
    align-items: baseline;
    box-shadow:  -15px 0px 15px -12px #626262,  15px 0px 15px -12px #626262;
    height: 60px;
  }
  .dark {
    background-color: #3e3e3e;
    box-shadow: -15px 0px 15px -5px #626262, 15px 0px 15px -5px #626262 !important;
  }
 .tab {
    height: 42px;
  }

  .tab{
    background-color: inherit;
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 16px;
    margin: 0 5px;
    transition: background 0.4s linear;
  }
  .tab svg{
    color: #7e7e7e;
  }

  .tab i{
    font-size: 1.2em;
  }
  .tab p{
    font-weight: bold;
    overflow: hidden;
    max-width: 0;
    white-space: nowrap;
    /* font-family: monospace; */
    font-size: 15px;
  }
  .tab.active p{
    margin-left: 10px;
    max-width: 100px;
    transition: max-width 0.4s linear;
  }
  .tab.active{
    margin-top: 10px !important;
  }
  .tab.active svg{
    color: #1c96a2 !important;
  }
  .dark .tab.active svg{
    color: #cccccc !important;
  }

  /* .tab.active.purple{
    background-color: rgba(91,55, 183, 0.2);
    color: rgba(91,55, 183, 1);
  }
  .tab.active.pink{
    background-color: rgba(201,55, 157, 0.2);
    color: rgba(201,55, 157, 1);
  }
  .tab.active.yellow{
    background-color: rgba(230,169, 25, 0.2);
    color: rgba(230,169, 25, 1);
  } */
  .tab.active.teal{
    background-color: rgba(28,150,162,0.2);    
    color: #1c96a2 !important;
  }

  .dark .tab.active.teal{
    background-color: #1c96a285;
    color: #cccccc !important;
  }
 